.footer
	color: $color-gray-3
	z-index: 100
	.widgettitle
		display: block
		font-size: 14px
		font-weight: 600
		line-height: 1
		margin-top: 0
		margin-bottom: 1em
		&:after
			display: none
.footer__divider
	display: block
	position: absolute
	top: 0
	left: calc(var(--bs-gutter-x) / 2)
	right: calc(var(--bs-gutter-x) / 2)
	height: 1px
	padding-left: 0
	padding-right: 0
	width: auto
	background-color: $color-border-dark

