.input-float
	position: relative
	display: block
	width: 100%
	margin-bottom: 0
.input-float__label
	position: absolute
	top: 0.75em
	left: 0
	margin: auto
	display: block
	font-size: 18px
	font-weight: 600
	@include trans1
	transform-origin: left center
	cursor: text
	color: $color-dark-1
	@media screen and (max-width: $xs)
		font-size: 17px
.input-float__input
	display: block
	width: 100%
	width: 100%
	border: 1px solid $color-dark-1
	border-top: none
	border-right: none
	border-left: none
	outline: none
	padding: 15px 0 4px
	margin-top: 20px
	font-weight: 600
	font-size: 17px
	+trans1
	background-color: transparent
	border-radius: 0
	color: inherit
.input-float__input_textarea
	resize: none
	height: calc( 100% - 36px)




.input-float__input_focused, .input-float__input_not-empty
	+ .input-float__label
		transform: scale(0.9) translateY(-1.5rem)
		

.input-float__input_focused
	border-color: $color-dark-1
	+ .input-float__label
			color: $color-dark-1


.dropdown
	margin-top: 40px