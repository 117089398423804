#page-header
	background: #fff
	.bg-gray-1
		transition: all .1s ease
		a
			font-size: 14px
			color: var(--color-dark-4)
			margin-right: 20px
			padding: 6px 0px
			text-transform: uppercase
			img
				margin-right: 3px
				width: 18px !important
		a:last-child
			margin-right: 0px
			background: var(--color-dark-1)
			color: #fff
			padding-right: 10px
			padding-left: 10px
			font-weight: 600
			img
				width: 20px !important
				position: relative
				top: -2px
	.border-bottom
		padding-top: 6px
		padding-bottom: 6px
		border-bottom: 1px solid #bfbfbf !important
		@media screen and (max-width: $lg)
			padding-top: 10px
			padding-bottom: 10px

	.menu-scroll
		padding-top: 5px
		padding-bottom: 6px

	.menu
		.menu-item-has-children, .menu-item
			a
				text-transform: uppercase
				color: var(--color-dark-4)
				font-weight: 700 !important
				letter-spacing: 0.02rem
				font-size: 14.5px
				@media screen and (max-width: $lg)
					font-size: 14px
		.current-menu-item
			a
				color: var(--color-dark-1)
		.denunciar
			background: var(--color-red)
			color: #fff !important
			padding: 0.3em 1em
			margin-top: 0.2em

			&:hover
				text-decoration: underline
	.logo__wrapper-img
		img
			@media screen and (max-width: $lg)
				max-width: 180px

.form-contact
	.boton
		span
			color: #fff
			font-weight: 700 !important
			font-size: 15px !important

.header_sticky
	.bg-gray-1
		height: 0px
		opacity: 0
		visibility: hidden
		transition: all .1s ease
	.logo__img-primary
		width: 190px
	.border-bottom
		padding-top: 4px
		padding-bottom: 4px

.pt-header
	margin-top: 130px
	@media screen and (max-width: $lg)
		margin-top: 60px

.color-blue
	color: var(--color-blue)

span
	color: var(--color-red)

.fw-500
	font-weight: 500 !important

[data-arts-theme-text=dark]
	strong
		color: var(--color-dark-1)

[data-arts-theme-text=light]
	li
		color: #fff !important

.section__intro
	.section-fullheight__inner
		height: 380px
		min-height: 380px
		@media screen and (max-width: $sm)
			height: 340px
			min-height: 340px


.border-bottom
	border-bottom: 1px solid rgba(#17245f, 0.5) !important

#procedimiento-de-siniestro
	@media screen and (max-width: $sm)
		background: var(--color-gray-1)

.fix-text
	@media screen and (max-width: $md)
		text-align: center
	.section__headline
		@media screen and (max-width: $md)
			margin-left: auto !important
			margin-right: auto !important

.fix-text_2
	@media screen and (max-width: $sm)
		text-align: center
	.section__headline
		@media screen and (max-width: $sm)
			margin-left: auto !important
			margin-right: auto !important			

.fix-bg
	position: relative
	padding: 70px 10px 60px 10px
	@media screen and (max-width: $xs)
		padding-top: 50px
		padding-bottom: 40px


	&::after
		content: ""
		background: var(--color-gray-1)
		width: 50%
		position: absolute
		height: 100%
		left: 0
		z-index: -1
		top: 0
		@media screen and (max-width: $sm)
			display: none

.content-icon
	max-width: 580px
	margin: 0 auto
	padding-left: 15px
	padding-right: 15px
	.icon
		border-bottom: 1px solid rgba(#17245f, 0.5) !important
		padding-bottom: 20px
		padding-top: 20px
		@media screen and (max-width: $lg)
			flex-direction: column
			text-align: center

		.img
			min-width: 120px
			@media screen and (max-width: $xxl)
				min-width: 110px
			@media screen and (max-width: $xl)
				min-width: 100px
			@media screen and (max-width: $lg)
				min-width: 90px
				padding-bottom: 20px
				margin: 0 auto
			img
				@media screen and (max-width: $xxl)
					width: 75px
				@media screen and (max-width: $xl)
					width: 70px
				@media screen and (max-width: $xs)
					width: 60px	
	.border-none
		border-bottom: none !important

.importante
	background: var(--color-red)
	padding: 20px 16px
	max-width: 620px
	margin: 0 auto
	margin-top: 20px
	@media screen and (max-width: $xxl)
		width: calc( 100% - 40px )
		padding: 16px 16px
	@media screen and (max-width: $lg)
		flex-direction: column
		text-align: center

	.img
		min-width: 60px
		margin-right: 10px
		@media screen and (max-width: $xl)
			min-width: 50px
			margin-right: 10px
		@media screen and (max-width: $lg)
			img
				width: 50px
				margin-bottom: 4px

	p
		color: #fff
		font-weight: 700
		line-height: 1.3

.circle
	padding-left: 20px !important
	position: relative
	li
		list-style: none
		position: relative
		margin-bottom: 3px
		@media screen and (max-width: $sm)
			margin-bottom: 5px
		&:before
			content: ''
			left: -22px
			position: absolute
			width: 5px
			height: 5px
			top: 10px
			background: #fff
			border-radius: 50%
			@media screen and (max-width: $sm)
				left: 0px
				position: relative
				display: inline-block
				margin-right: 10px
				top: 0px
	

.check
	padding-left: 0px
	&.white
		li
			color: #fff !important
			.arts-split-text__line:before
				background-image: url('../img/icon_check_mini_blanco.png') !important
	li
		list-style: none
		position: relative
		margin-bottom: 3px
		.arts-split-text__line:before
			content: ''
			position: relative
			background-image: url('../img/icon_check_mini_azul.png')
			background-size: 16px 16px
			padding-right: 24px
			top: 4px
			background-repeat: no-repeat
			width: 16px
			height: 16px
			display: inline-block
			left:0px
			

.boton_1
	width: 180px
	display: block
	color: #fff !important
	text-transform: uppercase
	font-size: 14px
	font-weight: 700
	@media screen and (max-width: $lg)
		margin: 0 auto
		text-align: left
	&.size_2
		font-size: 16px
		width: 208px
		.arts-split-text__line
			padding: 8px 12px
		::after
			background-size: 16px 16px
			width: 16px
			height: 16px

	.arts-split-text__line
		background: var(--color-red)
		padding: 8px 10px
	&:hover
		text-decoration: underline
	::after
		content: ''
		position: absolute
		background-image: url('../img/icon_compartir.png')
		background-size: 13px 13px
		background-repeat: no-repeat
		width: 13px
		height: 13px
		right: 8px
		margin-top: 3px

.boton
	display: block
	color: #fff
	text-transform: uppercase
	font-size: 15px
	font-weight: 700
	background: #E10600
	padding: 12px 15px
	text-align: center
	@media screen and (max-width: $sm)
		font-size: 14px
		padding: 10px 12px
	&:hover
		color: #fff
		background: rgba(#E10600, 0.8)
	&.azul
		background: #17245F
		&:hover
			color: #fff
			background: rgba(#17245F, 0.8)

.section__img
	.section-fullheight__inner
		min-height: auto !important
	.fix-width
		width: 400px !important
		@media screen and (max-width: $xs)
			width: 100%
	
	li, p
		font-size: 18px

.listado
	max-width: 500px
	margin: 0 auto
	.img
		width: 64px
		min-width: 64px
		margin-right: 20px
		@media screen and (max-width: $md)
			width: 50px
			min-width: 50px
		@media screen and (max-width: $xs)
			width: 45px
			min-width: 45px	
			margin-right: 10px



.modal__close
	@media screen and (max-width: $xs)
		img
			width: 30px

		



.header__wrapper-overlay-menu
	.denunciar
		.arts-split-text__line
			background: var(--color-red)
			padding: 6px 10px	
		.menu-overlay__heading
			color: #fff !important
			