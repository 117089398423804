.menu-overlay
	+reset-ul
	// position: relative
	display: flex
	flex-wrap: wrap
	margin: auto 0
	z-index: 50
	> li
		display: flex
		align-items: center
		flex: 1 0 50%
		@extend .my-xsmall
		> a
			display: inline-block
	li a .menu-overlay__heading .arts-split-text__line
			display: inline-block !important
			background-image: linear-gradient(currentColor, currentColor)
			background-position: 100% 100%
			background-repeat: no-repeat
			background-size: 0% 1px
			transition: background-size .3s ease, color 0.2s ease-in-out
	> li.current-menu-item
		> a .menu-overlay__heading .arts-split-text__line
			background-size: 100% 1px
			background-position: 0% 100%
	> li.current-menu-ancestor
		> a .menu-overlay__heading .arts-split-text__line
			background-size: 100% 1px
			background-position: 0% 100%
		.sub-menu li.current-menu-ancestor > a .menu-overlay__heading .arts-split-text__line
			background-size: 100% 1px
			background-position: 0% 100%
		.sub-menu li.current-menu-item > a .menu-overlay__heading .arts-split-text__line
			background-size: 100% 1px
			background-position: 0% 100%
	li a
		&:hover
			.menu-overlay__heading .arts-split-text__line
				background-position: 0% 100%
				background-size: 100% 1px
.menu-overlay_hover
	li a:not(:hover) .menu-overlay__heading .arts-split-text__line
		background-position: 100% 100%
	> li.current-menu-item
		> a:not(:hover) .menu-overlay__heading .arts-split-text__line
			background-size: 0% 1px
	> li.current-menu-ancestor
		> a:not(:hover) .menu-overlay__heading .arts-split-text__line
			background-size: 0% 1px
		.sub-menu li.current-menu-ancestor > a:not(:hover) .menu-overlay__heading .arts-split-text__line
			background-size: 0% 1px
		.sub-menu li.current-menu-item > a:not(:hover) .menu-overlay__heading .arts-split-text__line
			background-size: 0% 1px
.menu-overlay__item-wrapper
	width: 100%
	height: 100%
.menu-overlay .sub-menu
	display: inline-flex
	flex-direction: column
	justify-content: center
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	padding: calc(var(--gutter-vertical) * 2) var(--gutter-horizontal)
	// padding-top: calc(var(--gutter-vertical) * 2)
	// padding-right: var(--gutter-horizontal)
	// padding-bottom: calc(var(--gutter-vertical) * 2)
	// padding-left: 0
	list-style-type: none
	z-index: -1
	opacity: 0
	visibility: hidden
	max-height: 100vh
	> li
		display: block
		> a
			display: inline-block
			padding: 8px 0
.menu-overlay__subheading
	display: block
[data-arts-header-animation='intransition']
	.menu-overlay li a .menu-overlay__heading .arts-split-text__line
		background-position: 100% 100% !important
		background-size: 0% 1px !important
		transition: background-size .2s ease, color 0.2s ease-in-out !important

@media screen and (max-width: $sm)
	.menu-overlay
		display: block
		margin-top: 0
	.menu-overlay > li
		flex: 1 1 100%
		width: 100%
	.menu-overlay > li
		margin-top: 2em
		margin-bottom: 2em
	.menu-overlay > li:first-child
		margin-top: 0
	.menu-overlay > li:last-child
		margin-bottom: 0
	.menu-overlay .sub-menu
		justify-content: flex-start
		padding-top: 5em
