.widget_nav_menu
	ul.menu
		> li
			white-space: normal
			word-break: break-word
			a
				display: inline-block
				padding: 0
				font-size: 15px
				line-height: 1.3
				font-weight: normal
				text-transform: none
				letter-spacing: 0
				// color: var(--color-gray-1)
				// &:after, &:before
				// 	display: none
				// &:hover
				// 	color: var(--color-dark-1)
		> li.menu-item-has-children
			margin-bottom: 1.5em !important
			a
				&:after
					display: none
