select
	display: block
	border: 1px solid $color-dark-1
	border-radius: 2px
	padding: 8px 35px 8px 15px
	background-color: transparent
	outline: none
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none
	background-image: url('../img/general/select_black.png')
	background-position: right 15px bottom 50%
	background-repeat: no-repeat
	background-size: 8px 6px
