.list-backgrounds_striped
	.list-backgrounds__item:nth-of-type(odd)
		background-color: $color-light-2
	.list-backgrounds__item:nth-of-type(even)
		background-color: $color-light-1
.list-backgrounds__item
	position: relative
	overflow: hidden
	clip-path: inset(0px)
.list-backgrounds__wrapper-img
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	> *
		height: 100%
	img
		transform: scale(1.03)
.list-backgrounds__overlay
	z-index: 1 !important
	transform: translateZ(0)
.list-backgrounds__works-list
	+reset-ul
	li
		margin-top: 0.5em
		margin-bottom: 0.5em
		+trans1
		&:first-child
			margin-top: 0
		&:last-child
			margin-bottom: 0
.list-backgrounds__overlay
	+trans1
.list-backgrounds__item_hover
	.list-backgrounds__overlay
		opacity: 0.6
